'use client';

import {useTranslations} from 'next-intl';
import Link from 'next/link';
import {useEffect} from 'react';
import Header from '../../components/header/Header';
import logger from '../../logger';

export default function Error({
  error,
  reset,
}: {
  error: Error & {digest?: string};
  reset: () => void;
}) {
  const t = useTranslations('common');

  useEffect(() => {
    logger.error(error);
  }, [error]);

  return (
    <>
      <Header />
      <div className="container mx-auto p-8">
        <div className="flex flex-row items-center gap-4 mb-8">
          <div className="text-3xl font-bold text-gray-500">{t('ErrorPage.error')}</div>
        </div>
        <Link href="/" className="mt-8 text-primary hover:text-primary decoration-primary underline hover:no-underline">
          {t('ErrorPage.return-to-home')}
        </Link>
      </div>
    </>
  );
}
